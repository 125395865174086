export const appHead = {"meta":[{"charset":"utf-8"},{"name":"viewport","content":"width=device-width, initial-scale=1.0, maximum-scale=1.0, minimum-scale=1.0, user-scalable=no, shrink-to-fit=no"},{"name":"keywords","content":"HDEDU,HD EDU,EDU,hdedu,hd,edu,HD,EDUCATION,haidao,海道,线上,教育,职场,讲座,中国,留学生,学业辅导,学习资料,补习培训,职业规划,能力提升"},{"name":"description","content":"英国伦敦本土机构安全保障，小鸥国际教育（Sterna Educational International）英国文化教育协会认证合作机构深耕英国英语培训咨询多年。公司凭借完善的服务体系和经验丰富的专业团队，致力于确保每位学生的学习成果。小鸥英国团队由100%硕士及以上学历的专家组成，旨在为您提供在英国美好的学习体验，让您尽享英国的无忧学习与生活。"},{"name":"format-detection","content":"telephone=no"}],"link":[],"style":[],"script":[{"src":"/layui.js"}],"noscript":[],"htmlAttrs":{"lang":"zh-CN"},"title":"体验沉浸式英语体验，签证报名一站式服务 - 小鸥出国学英语"}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appViewTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const appTeleportTag = "div"

export const appTeleportId = "teleports"

export const renderJsonPayloads = true

export const componentIslands = false

export const payloadExtraction = true

export const cookieStore = false

export const appManifest = true

export const remoteComponentIslands = false

export const selectiveClient = false

export const devPagesDir = null

export const devRootDir = null

export const devLogs = false

export const nuxtLinkDefaults = {"componentName":"NuxtLink"}

export const asyncDataDefaults = {"deep":true}

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'

export const viewTransition = false