import { default as aboutytazby1XzsMeta } from "/www/wwwroot/lanSchool/pages/about.vue?macro=true";
import { default as _91id_93B4DBdDRhMYMeta } from "/www/wwwroot/lanSchool/pages/articles/[id].vue?macro=true";
import { default as _91id_933K6QexUd3nMeta } from "/www/wwwroot/lanSchool/pages/articles/types/[id].vue?macro=true";
import { default as _91id_9377Q43xaZ0rMeta } from "/www/wwwroot/lanSchool/pages/citys/[id].vue?macro=true";
import { default as _91id_93pwMTeC0G4jMeta } from "/www/wwwroot/lanSchool/pages/courses/[id].vue?macro=true";
import { default as indexMU3YIJ0SjeMeta } from "/www/wwwroot/lanSchool/pages/index.vue?macro=true";
import { default as _91id_93e8yxdECtwJMeta } from "/www/wwwroot/lanSchool/pages/projects/[id].vue?macro=true";
import { default as indexLD5IAHmYMuMeta } from "/www/wwwroot/lanSchool/pages/projects/index.vue?macro=true";
import { default as _91id_93LR0wlK1J0UMeta } from "/www/wwwroot/lanSchool/pages/schools/[id].vue?macro=true";
import { default as signupzVaM0S8WafMeta } from "/www/wwwroot/lanSchool/pages/signup.vue?macro=true";
import { default as _91id_93VS62xUcQa9Meta } from "/www/wwwroot/lanSchool/pages/tags/[id].vue?macro=true";
export default [
  {
    name: aboutytazby1XzsMeta?.name ?? "about",
    path: aboutytazby1XzsMeta?.path ?? "/about",
    meta: aboutytazby1XzsMeta || {},
    alias: aboutytazby1XzsMeta?.alias || [],
    redirect: aboutytazby1XzsMeta?.redirect,
    component: () => import("/www/wwwroot/lanSchool/pages/about.vue").then(m => m.default || m)
  },
  {
    name: _91id_93B4DBdDRhMYMeta?.name ?? "articles-id",
    path: _91id_93B4DBdDRhMYMeta?.path ?? "/articles/:id()",
    meta: _91id_93B4DBdDRhMYMeta || {},
    alias: _91id_93B4DBdDRhMYMeta?.alias || [],
    redirect: _91id_93B4DBdDRhMYMeta?.redirect,
    component: () => import("/www/wwwroot/lanSchool/pages/articles/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_933K6QexUd3nMeta?.name ?? "articles-types-id",
    path: _91id_933K6QexUd3nMeta?.path ?? "/articles/types/:id()",
    meta: _91id_933K6QexUd3nMeta || {},
    alias: _91id_933K6QexUd3nMeta?.alias || [],
    redirect: _91id_933K6QexUd3nMeta?.redirect,
    component: () => import("/www/wwwroot/lanSchool/pages/articles/types/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_9377Q43xaZ0rMeta?.name ?? "citys-id",
    path: _91id_9377Q43xaZ0rMeta?.path ?? "/citys/:id()",
    meta: _91id_9377Q43xaZ0rMeta || {},
    alias: _91id_9377Q43xaZ0rMeta?.alias || [],
    redirect: _91id_9377Q43xaZ0rMeta?.redirect,
    component: () => import("/www/wwwroot/lanSchool/pages/citys/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93pwMTeC0G4jMeta?.name ?? "courses-id",
    path: _91id_93pwMTeC0G4jMeta?.path ?? "/courses/:id()",
    meta: _91id_93pwMTeC0G4jMeta || {},
    alias: _91id_93pwMTeC0G4jMeta?.alias || [],
    redirect: _91id_93pwMTeC0G4jMeta?.redirect,
    component: () => import("/www/wwwroot/lanSchool/pages/courses/[id].vue").then(m => m.default || m)
  },
  {
    name: indexMU3YIJ0SjeMeta?.name ?? "index",
    path: indexMU3YIJ0SjeMeta?.path ?? "/",
    meta: indexMU3YIJ0SjeMeta || {},
    alias: indexMU3YIJ0SjeMeta?.alias || [],
    redirect: indexMU3YIJ0SjeMeta?.redirect,
    component: () => import("/www/wwwroot/lanSchool/pages/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93e8yxdECtwJMeta?.name ?? "projects-id",
    path: _91id_93e8yxdECtwJMeta?.path ?? "/projects/:id()",
    meta: _91id_93e8yxdECtwJMeta || {},
    alias: _91id_93e8yxdECtwJMeta?.alias || [],
    redirect: _91id_93e8yxdECtwJMeta?.redirect,
    component: () => import("/www/wwwroot/lanSchool/pages/projects/[id].vue").then(m => m.default || m)
  },
  {
    name: indexLD5IAHmYMuMeta?.name ?? "projects",
    path: indexLD5IAHmYMuMeta?.path ?? "/projects",
    meta: indexLD5IAHmYMuMeta || {},
    alias: indexLD5IAHmYMuMeta?.alias || [],
    redirect: indexLD5IAHmYMuMeta?.redirect,
    component: () => import("/www/wwwroot/lanSchool/pages/projects/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93LR0wlK1J0UMeta?.name ?? "schools-id",
    path: _91id_93LR0wlK1J0UMeta?.path ?? "/schools/:id()",
    meta: _91id_93LR0wlK1J0UMeta || {},
    alias: _91id_93LR0wlK1J0UMeta?.alias || [],
    redirect: _91id_93LR0wlK1J0UMeta?.redirect,
    component: () => import("/www/wwwroot/lanSchool/pages/schools/[id].vue").then(m => m.default || m)
  },
  {
    name: signupzVaM0S8WafMeta?.name ?? "signup",
    path: signupzVaM0S8WafMeta?.path ?? "/signup",
    meta: signupzVaM0S8WafMeta || {},
    alias: signupzVaM0S8WafMeta?.alias || [],
    redirect: signupzVaM0S8WafMeta?.redirect,
    component: () => import("/www/wwwroot/lanSchool/pages/signup.vue").then(m => m.default || m)
  },
  {
    name: _91id_93VS62xUcQa9Meta?.name ?? "tags-id",
    path: _91id_93VS62xUcQa9Meta?.path ?? "/tags/:id()",
    meta: _91id_93VS62xUcQa9Meta || {},
    alias: _91id_93VS62xUcQa9Meta?.alias || [],
    redirect: _91id_93VS62xUcQa9Meta?.redirect,
    component: () => import("/www/wwwroot/lanSchool/pages/tags/[id].vue").then(m => m.default || m)
  }
]